import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import { LayoutLp, SEO, AssetImage, LWrap } from '../../../components/_index';
import Slider from 'react-slick';
import { useMobile } from '../../../utils/use-series';
import '../../../assets/_sass/page/sp/afternoon.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const isSp = useMobile();
  const settings = {
    dots: true,
    arrows: false,
    autoplay: false,
    speed: 1500,
  };

  // SPテキストの「続きを見る」をセクションごとに制御
  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean;
  }>({});

  const handleShowMore = (sectionId: string) => {
    setExpandedSections({
      ...expandedSections,
      [sectionId]: !expandedSections[sectionId],
    });
  };

  return (
    <LayoutLp isAfternoon={true}>
      <SEO
        title={frontmatter?.title}
        description={frontmatter?.description}
        ogimage={`${process.env.BASE_URL}/assets/images/sp/afternoon/ogimage.png`}
      />
      <main>
        <div className="p_afternoon">
          <section className="lp_kv">
            {!isSp ? (
              <figure className={`imgWrapper`}>
                <AssetImage
                  src="/assets/images/sp/afternoon/kv.png"
                  alt=""
                  loading="lazy"
                />
              </figure>
            ) : (
              <figure className={`imgWrapper`}>
                <AssetImage
                  src="/assets/images/sp/afternoon/kv__sp.png"
                  alt=""
                  loading="lazy"
                />
              </figure>
            )}
          </section>
          <div className="lp_kvLead">
            <div className="lp_kvLeadText">
              ロイヤルパークホテルズの
              <br className="u_sp" />
              「アフタヌーンティーセット」
              <br />
              各ホテル限定メニューをこの機会にぜひお楽しみください
            </div>
          </div>

          <section className="lp_sect u_pt100">
            <div className="lp_sectWrapper">
              <div className="lp_sectSlider">
              {!isSp ? (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider01.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider01__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                )}
              </div>
              <div className="lp_lead">
                <div className="lp_contentsNumber">
                  <span>01</span>丸ノ内ホテル
                </div>
                <div className="lp_setPlanTitle">
                  クリスマスアフタヌーンティー
                </div>
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section1'] ? 'expanded' : ''
                  }`}
                >
                  クリスマスを彩る、アールグレイの風味豊かなマカロンや、濃厚なピスタチオにイチゴの甘さと酸味が絶妙にマッチしたタルトなどのスイーツやリースのような華やかな盛り付けのセイボリーがクリスマス気分を一層盛りあげます♪</p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section1')}
                  >
                    {expandedSections['section1']
                      ? '表示を減らす'
                      : '続きを見る'}
                  </span>
                )}
                <p className="lp_moreButton">
                  <Link
                    to="https://www.marunouchi-hotel.co.jp/tpx-afternoontea202412/"
                    target="_blank"
                  >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </p>
              </div>
            </div>
          </section>

          <section className="lp_sect">
            <div className="lp_sectWrapper lp_sectWrapper__reverse">
              <div className="lp_sectSlider">
              {!isSp ? (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider02.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider02_02.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider02_03.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider02__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider02_02__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider02_03__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                )}
              </div>
              <div className="lp_lead">
                <div className="lp_contentsNumber">
                  <span>02</span>ザ ロイヤルパークホテル アイコニック 東京汐留
                </div>
                <div className="lp_setPlanTitle">
                  AFTERNOON TEA 
                  <br />
                  with SIROCCO 
                  <br />
                  「ホワイトクリスマス」
                </div>
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section4'] ? 'expanded' : ''
                  }`}
                >
                  「ブリリアントホワイトクリスマス」な館内装飾に「ホワイトクリスマス」なアフタヌーンティー。ウェッジウッド「フェスティビティ」シリーズからほっと優しいオフホワイトのティーセットも初登場し、特別なクリスマス気分を盛りあげます。
                </p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section4')}
                  >
                    {expandedSections['section4']
                      ? '表示を減らす'
                      : '続きを見る'}
                  </span>
                )}
                <p className="lp_moreButton">
                  <Link
                    to="https://www.royalparkhotels.co.jp/ic/tokyoshiodome/news/0p1jfwdzu6/"
                    target="_blank"
                  >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </p>
              </div>
            </div>
          </section>

          <section className="lp_sect">
            <div className="lp_sectWrapper">
              <div className="lp_sectSlider">
              {!isSp ? (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider03.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider03_02.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider03__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider03_02__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                )}
              </div>
              <div className="lp_lead">
                <div className="lp_contentsNumber">
                  <span>03</span>ロイヤルパークホテル（東京・日本橋）　ロビーラウンジ　フォンテーヌ
                </div>
                <div className="lp_setPlanTitle">
                  クリスマスアフタヌーンティー
                </div>
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section5'] ? 'expanded' : ''
                  }`}
                >
                  くまのサンタのチョコレート細工や、スノーマンのバニラマカロン、かわいらしいクリスマスモチーフでカラフルに彩られた心華やぐアフタヌーンティー。たっぷりとムードを盛りあげるクリスマスらしい華やかなセイボリーもお楽しみください。
                </p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section5')}
                  >
                    {expandedSections['section5']
                      ? '表示を減らす'
                      : '続きを見る'}
                  </span>
                )}
                <p className="lp_moreButton">
                  <Link
                      to="https://www.rph.co.jp/restaurants/plan/u5s17xuh6i/"
                      target="_blank"
                    >
                      MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </p>
              </div>
            </div>
          </section>

          <section className="lp_sect">
            <div className="lp_sectWrapper lp_sectWrapper__reverse">
              <div className="lp_sectSlider">
              {!isSp ? (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider04.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider04_02.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider04_03.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider04__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider04_02__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider04_03__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                )}
              </div>
              <div className="lp_lead">
                <div className="lp_contentsNumber">
                  <span>04</span>ロイヤルパークホテル（東京・日本橋）　中国料理　桂花苑
                </div>
                <div className="lp_setPlanTitle">
                  チャイニーズクリスマスアフタヌーンティー
                </div>
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section6'] ? 'expanded' : ''
                  }`}
                >
                  クリスマスカラーで彩りを添えたチャイニーズアフタヌーンティー。2つの味わいを包んだ雪だるま饅頭やクリスマスリースの蒸しカステラがクリスマスの雰囲気を演出。香り豊かな中国茶とともに中国料理ならではの特別なメニューをご堪能ください。
                </p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section6')}
                  >
                    {expandedSections['section6']
                      ? '表示を減らす'
                      : '続きを見る'}
                  </span>
                )}
                <p className="lp_moreButton">
                  <Link
                    to="https://www.rph.co.jp/restaurants/plan/8yhmb3tiv/"
                    target="_blank"
                  >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </p>
              </div>
            </div>
          </section>

          <section className="lp_sect">
            <div className="lp_sectWrapper">
              <div className="lp_sectSlider">
              {!isSp ? (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider05.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider05__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>     
                    </Slider>
                  </>
                )}
              </div>
              <div className="lp_lead">
                <div className="lp_contentsNumber">
                  <span>05</span>仙台ロイヤルパークホテル
                </div>
                <div className="lp_setPlanTitle">
                  クリスマスアフタヌーンティーセット
                </div>
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section7'] ? 'expanded' : ''
                  }`}
                >
                 可愛らしい雪だるまがお出迎え、この時季ならではのクリスマスデコレーションされたスイーツやセイボリーをご堪能いただけます。ラウンジから見える冬のガーデンを眺めながら、贅沢なホリデーシーズンをお過ごしください。
                </p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section7')}
                  >
                    {expandedSections['section7']
                      ? '表示を減らす'
                      : '続きを見る'}
                  </span>
                )}
                <p className="lp_moreButton">
                  <Link
                      to="https://www.srph.co.jp/pickup/ubmlyyjtwe2/"
                      target="_blank"
                    >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </p>
              </div>
            </div>
          </section>

          <section className="lp_sect">
            <div className="lp_sectWrapper lp_sectWrapper__reverse">
              <div className="lp_sectSlider">
              {!isSp ? (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider06.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider06__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>     
                    </Slider>
                  </>
                )}
              </div>
              <div className="lp_lead">
                <div className="lp_contentsNumber">
                  <span>06</span>ザ ロイヤルパークキャンバス 札幌大通公園
                </div>
                <div className="lp_setPlanTitle">
                KAMUY パークビューアフタヌーンティー ～白銀の大通公園、ほっこりする冬のひととき～
                </div>
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section7'] ? 'expanded' : ''
                  }`}
                >
                 本格的な雪の季節を迎える札幌に、美しい雪景色をイメージしたアフタヌーンティーが登場。フロマージュモンブランやバニラマカロンなどのスイーツや、心も身体もあたたまるドリンクとともにほっこり穏やかな午後のひとときをお過ごしください。
                </p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section7')}
                  >
                    {expandedSections['section7']
                      ? '表示を減らす'
                      : '続きを見る'}
                  </span>
                )}
                <p className="lp_moreButton">
                  <Link
                      to="https://www.royalparkhotels.co.jp/canvas/sapporoodoripark/news/ppslz0jf0r/"
                      target="_blank"
                    >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </p>
              </div>
            </div>
          </section>

          <section className="lp_sect">
            <div className="lp_sectWrapper">
              <div className="lp_sectSlider">
              {!isSp ? (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07_02.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07_03.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07_04.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07_05.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07_06.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07_02__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07_03__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07_04__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07_05__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07_06__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                )}
              </div>
              <div className="lp_lead">
                <div className="lp_contentsNumber">
                  <span>07</span>ザ ロイヤルパークホテル アイコニック 大阪御堂筋
                </div>
                <div className="lp_setPlanTitle">
                  クリスマスアフタヌヌーンティーCadeaux de Noël
                </div>
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section2'] ? 'expanded' : ''
                  }`}
                >
                  クリスマスモチーフがツリーを彩るオーナメントのような心おどるアフタヌーンティー。フランス料理の技法と食材のハーモニーを、ロビーに瞬くクリスマスツリーの輝きとともに、ゆったりとしたソファ席でお楽しみください。
                  </p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section2')}
                  >
                    {expandedSections['section2']
                      ? '表示を減らす'
                      : '続きを見る'}
                  </span>
                )}
                <p className="lp_moreButton">
                  <Link
                    to="https://www.royalparkhotels.co.jp/ic/osakamidosuji/news/ft7nj6pfapap/"
                    target="_blank"
                  >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </p>
              </div>
            </div>
          </section>

          <section className="lp_sect">
            <div className="lp_sectWrapper lp_sectWrapper__reverse">
              <div className="lp_sectSlider">
              {!isSp ? (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider08.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider08_02.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider08_03.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider08_04.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider08__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider08_02__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider08_03__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider08_04__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                )}
              </div>
              <div className="lp_lead">
                <div className="lp_contentsNumber">
                  <span>08</span>横浜ロイヤルパークホテル デリカ＆ラウンジ コフレ
                </div>
                <div className="lp_setPlanTitle">
                  ランドマークアフタヌーンティー“Christmas”
                </div>
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section2'] ? 'expanded' : ''
                  }`}
                >
                  華やかなリボンをあしらった横浜ランドマークタワー型のスタンドには、まるで絵本から飛び出してきたクリスマスの世界観が広がります。可愛らしいサンタクロースの帽子をかぶったクマのレアチーズケーキや雪だるまマカロンとクリスマス気分を♪</p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section2')}
                  >
                    {expandedSections['section2']
                      ? '表示を減らす'
                      : '続きを見る'}
                  </span>
                )}
                <p className="lp_moreButton">
                  <Link
                    to="https://www.yrph.com/restaurants/teatime/teatime/#coffret"
                    target="_blank"
                  >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </p>
              </div>
            </div>
          </section>

          <section>
            <div className="instagram">
              <LWrap>
                <Link
                  to="https://www.instagram.com/royalparkhotels__official/"
                  target="_blank"
                >
                  <AssetImage
                    src="/assets/images/sp/afternoon/instagram.png"
                    alt=""
                    loading="lazy"
                  />
                </Link>
              </LWrap>
            </div>
          </section>
        </div>
      </main>
    </LayoutLp>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
